import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[15, 20, 30]],
  },
  container: {
    maxWidth: '37rem',
    margin: '0 auto',
  },
  register: {},
  terms: {
    color: theme.colors.gray500,
    fontSize: '1.2rem',
    marginBottom: '0.8rem',
    lineHeight: 1.333,
    marginTop: '1.3rem',
  },
  termsLink: {
    color: theme.colors.inlineLink,
  },
  requireStyle: {
    color: theme.colors.bgDark,
    fontSize: '1.2rem',
    marginBottom: '0.8rem',
    lineHeight: 1.14,
    marginTop: '1.6rem',
    '&:before': {
      content: '"*"',
      color: theme.colors.alert,
    },
  },
  requiredLabel: {
    '&:after': {
      content: '"*"',
      color: theme.colors.alert,
      marginLeft: '0.3rem',
    },
  },
  orStyle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '2rem 0',
  },
  genderContainer: {
    marginTop: '2.4rem',
  },
  asInputStyle: {
    textAlign: 'left',
    textTransform: 'none',
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: 1.25,
    letterSpacing: '-0.03em',
    paddingBottom: '0.8rem',
    margin: 0,
  },
  titleStyle: {
    textTransform: 'uppercase',
    margin: '0 0 1rem',
  },
  getSmsButtonContainer: {
    marginTop: '1.6rem',
  },
  boxGender: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '0.2rem',
  },
  gender: {
    marginRight: '1.6rem',
  },
  checkbox: {
    margin: 'var(--ui-spacer-l) 0',
  },
  buttons: {
    marginTop: `${theme.gap}rem`,
  },
  button: {
    marginBottom: '2rem',
  },
  dropdown: {
    width: '100%',
    marginTop: '4.6rem',
    minHeight: '3.6rem',
    '& label': {
      top: '-3rem',
      textTransform: 'uppercase',
      textAlign: 'left',
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: '-0.03em',
      paddingBottom: '0.8rem',
    },
  },
  errorStyle: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '.8rem 0',
    marginBottom: 0,
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    containerFluid: {
      padding: [[60, 0]],
      width: '100%',
    },
    container: {
      maxWidth: '37rem',
      margin: [[0, 'auto']],
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export default useStyles
