import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ErrorMessage,
  Form,
  useFormikContext,
} from 'formik'
import { isSafari } from 'react-device-detect';
import {
  customStylesLanguageDropdown,
} from '../../ui/dropdown/custom-styles'
// import combineClassNames from '../../helpers/combineClassNames';
import {
  BirthdayInput,
  Button,
  Checkbox,
  Dropdown,
  Input,
  InputPhone,
  // InputRadio,
  Link,
  TokenButton,
} from '../../ui'
import {
  useThemeConfig,
} from '../../hook'
import useStyles from './registration-style'

const RegistrationForm = ({
  fields,
  formDisabled,
  onLoginClick,
  onSocialSignIn,
  socialAvailable,
}) => {
  const { t } = useTranslation()
  const { getConfig } = useThemeConfig()
  const {
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
    setFieldTouched,
    submitForm,
    values,
    touched,
  } = useFormikContext()

  // [FL] Don't use page.privacy_url or page.terms_url from system settings!
  //      They are not for frontend
  const termsPageUrlSlug = getConfig('config.termsPageUrlSlug', 'terms')
  const privacyPageUrlSlug = getConfig('config.privacyPageUrlSlug', 'privacy')
  const termsUrl = `/pages/${termsPageUrlSlug}`
  const privacyUrl = `/pages/${privacyPageUrlSlug}`

  const handlePhoneChange = ({ countryCallingCode, localPhoneNumber }) => {
    setFieldValue('countryCallingCode', countryCallingCode)
    setFieldValue('localPhoneNumber', localPhoneNumber)
    const phone = !_.isEmpty(countryCallingCode) && !_.isEmpty(localPhoneNumber)
      ? `${countryCallingCode}${localPhoneNumber}`
      : ''
    setFieldValue('phone', phone)
  }

  const handleSelectionChange = (event) => {
    const {
      name,
      value,
    } = event
    setFieldValue(name, value)
  }

  const handleBirthdayChange = (value) => {
    if (!_.isEmpty(value)) {
      setFieldTouched('dateOfBirth')
    }
    setFieldValue('dateOfBirth', value)
  }

  const handleSubmitForm = () => {
    submitForm()
  }

  // styles
  const styles = useStyles({ isSafari })

  const renderErrorMessage = (msg) => <p className={styles.errorStyle}>{msg}</p>

  return (
    <div className={styles.register}>
      <p className={styles.titleStyle}>{t('screens.registration.register')}</p>

      {/* TODO: make a social sign in component */}
      { _.some(socialAvailable, ['available', true])
        && (
        <>
          {
            _.map(socialAvailable, (socialMedia) => {
              if (socialMedia.available) {
                return (
                  <Button
                    key={socialMedia.name}
                    facebook
                    text={t('screens.login.facebook')}
                    onClick={() => onSocialSignIn(socialMedia.name)}
                  />
                )
              }
              return null
            })
            }
          <p className={styles.orStyle}>
            -
            {' '}
            {t('screens.login.or')}
            {' '}
            -
          </p>
        </>
        )}
      <Form noValidate>
        {
          _.map(fields, (field) => {
            const {
              name,
              type,
              label,
              labelType,
              options,
            } = field
            let buttomProps = {}
            switch (type) {
              case 'phone':
                return (
                  <InputPhone
                    key={name}
                    {...field}
                    countryCallingCodeOptions={options}
                    defaultValue={{
                      countryCallingCode: values.countryCallingCode,
                    }}
                    onChange={handlePhoneChange}
                  />
                )
              case 'checkbox':
                return (
                  <Checkbox
                    formik
                    key={name}
                    checked={_.get(values, name)}
                    wrapperStyle={styles.checkbox}
                    {...field}
                    error={_.has(errors, name) && _.has(touched, name)}
                  >
                    {
                      labelType === 'i18nTransComponentKey'
                        ? (
                          <Trans
                            {...label}
                            components={
                              _.map(_.get(label, 'links', []), (link) => (
                                <Link gatsbyLink to={link} target="_blank" className={styles.termsLink}>
                                  {link}
                                </Link>
                              ))
                            }
                          />
                        )
                        : label
                    }
                  </Checkbox>
                )
              case 'select':
                return (
                  <>
                    <Dropdown
                      className={styles.dropdown}
                      customStyles={customStylesLanguageDropdown}
                      onChange={({ value }) => { handleSelectionChange({ name, value }) }}
                      isSearchable={false}
                      values={_.find(options, { value: _.get(values, name) })}
                      key={name}
                      {...field}
                    />
                    <ErrorMessage name={name} render={renderErrorMessage} />
                  </>
                )
              case 'datepicker':
                return (
                  <>
                    <BirthdayInput
                      key={name}
                      onChange={handleBirthdayChange}
                      {...field}
                    />
                    <ErrorMessage name={name} render={renderErrorMessage} />
                  </>
                )
              case 'sendTokenButton':
                switch (name) {
                  case 'smsTokenButton':
                    buttomProps = {
                      disabled: _.isEmpty(values.localPhoneNumber),
                      phone: `${_.get(values, 'countryCallingCode', '')}${_.get(values, 'localPhoneNumber', '')}`,
                    }
                    break;
                  case 'emailTokenButton':
                    buttomProps = {
                      disabled: _.isEmpty(values.email)
                        || _.has(errors, 'email'),
                      email: _.get(values, 'email', ''),
                    }
                    break;
                  default:
                    break;
                }
                return (
                  <div className={styles.getSmsButtonContainer}>
                    <TokenButton
                      dark
                      onSuccess={field.onSuccess}
                      onError={field.onError}
                      {...buttomProps}
                    />
                  </div>
                )
              default:
                break;
            }
            return (
              <Input
                formik
                key={name}
                {...field}
              />
            )
          })
        }
        <p className={styles.requireStyle}>{t('screens.registration.required')}</p>
        <p className={styles.terms}>
          <Trans i18nKey="screens.registration.terms">
            By signing up,you agree to our
            <Link gatsbyLink to={termsUrl} target="_blank" className={styles.termsLink}>Terms &amp; Conditions</Link>
            and
            <Link gatsbyLink to={privacyUrl} target="_blank" className={styles.termsLink}>Privacy Policy</Link>
            .
          </Trans>
        </p>
        <div className={styles.buttons}>
          <Button
            dark
            disabled={formDisabled || isSubmitting || !isValid}
            text={t('screens.registration.buttons.submit')}
            onClick={handleSubmitForm}
            className={styles.button}
          />
          <Button
            border
            text={t('screens.registration.buttons.haveAnAccount')}
            onClick={onLoginClick}
            className={styles.button}
          />
        </div>
      </Form>
    </div>
  )
}

export default RegistrationForm
