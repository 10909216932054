/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import URI from 'urijs'
import { Formik } from 'formik'
import {
  cancelRequest,
  useAuth,
  useAddresses,
  useMetaDefinitions,
  useSystemSettings,
  useUser,
  useOmnitechApp,
} from 'react-omnitech-api'
import {
  useAccount,
  useAlert,
  useAnalytics,
  useCart,
  useI18n,
  useLink,
  useOrderMethod,
  useThemeConfig,
  // usePriceTemplate,
} from '../../hook'
import {
  getSocialMediaAvailable,
  getRegistrationFormInitialValues,
  transformRegistrationFormValidationSchema,
} from '../../helpers'
import RegistrationForm from './registration-form'
import RegistrationView from './registration-view'

function RegistrationController({
  location, pathContext,
}) {
  // prepare hook
  const {
    getSupportedRegisterApproaches,
    getRegistrationApproach,
  } = useAccount()
  const alert = useAlert()
  const { navigate } = useLink()
  const { t } = useTranslation()
  const { fetchCountries } = useAddresses()
  const { getSystemSetting } = useSystemSettings()
  const { auth, createRegistration, setAuth } = useAuth()
  const {
    cart,
    cartId,
    getParams,
    mergeCart,
    updateCart,
  } = useCart()
  // const { code: CART_PRICE_TEMPLATE_KEY } = usePriceTemplate()
  const {
    orderMethod,
    store,
  } = useOrderMethod()
  const { availableLocales, locale } = useI18n()
  const { createUsersOmniAuthOtps } = useUser()
  const { api } = useOmnitechApp()
  const { trackEvent } = useAnalytics()
  const { metaDefinitions } = useMetaDefinitions()
  const { getConfig } = useThemeConfig()

  // hook with dependencies
  const preferredCountryCodes = getSystemSetting('country.preferred_country_codes')
  const genderSelection = getSystemSetting('account.gender_selection', [])
  const salutationSelection = getSystemSetting('account.salutation_selection', [])
  const formConfig = getSystemSetting('account.user_registration_fields', {})
  const lastNameFirst = getSystemSetting('last_name_first', false)
  const requireEmailToken = getSystemSetting(
    'account.email_token.required_for_registration',
    false,
  )
  const requireSmsToken = getSystemSetting(
    'account.sms_token.required_for_registration',
    false,
  )
  const birthdayInputFormat = getConfig('config.pages.registrationForm.birthdayInputFormat')
  const termsPageUrlSlug = getConfig('config.termsPageUrlSlug', 'terms')
  const picsPageUrlSlug = getConfig('config.picsPageUrlSlug', 'pics')
  const supportedRegisterApproaches = getSupportedRegisterApproaches()
  // TODO get array all social sign in enable
  const socialAvailable = getSocialMediaAvailable(getSystemSetting)

  const metaOptions = _.filter(metaDefinitions, ['metaModelName', 'user'])

  // internal states
  const [countriesEntities, setCountriesEntities] = useState([])
  const [defaultCountryCallingCode, setDefaultCountryCallingCode] = useState('')
  const [formDisabled, setFormDisabled] = useState(true)
  const [pageReady, setPageReady] = useState(false)
  const [showEmailVerificationCodeInput, setShowEmailVerificationCodeInput] = useState(false)
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false)

  // local variable
  const redirectUrl = useMemo(() => _.get(location, 'state.redirectUrl'), [location])
  const callbackState = useMemo(() => _.get(location, 'state.callbackState'), [location])
  const defaultValues = useMemo(() => _.get(location, 'state.values'), [location])
  const seoTitle = t('screens.registration.seo.title')

  // locale options
  const defaultLocale = locale
  const localeOptions = _.map(availableLocales, (availableLocale) => ({
    value: availableLocale,
    label: t('ui.locale', { context: availableLocale }),
  }))

  // salutation
  const salutationOptions = _.map(_.compact(salutationSelection), (sal) => ({
    value: sal,
    label: t('screens.registration.form.salutation.options', { context: sal }),
  }))
  // gender
  const genderOptions = _.map(_.compact(genderSelection), (gender) => ({
    value: gender,
    label: t(`screens.registration.form.gender.${gender}`),
  }))

  // useMemo for caching variables

  // define the form field sequence
  const availableFieldKeys = useMemo(() => ([
    // 'metaCustomerRankCode',
    // 'metaStaffStoreCode',
    // 'metaBranchCode',
    'email',
    'password',
    'salutation',
    ...(
      lastNameFirst
        ? ['lastName', 'firstName']
        : ['firstName', 'lastName']
    ),
    'gender',
    'dateOfBirth',
    'metaUserPreferDistrict',
    'locale',
    'affiliatedUserMembershipCode',
    'phone',
    'metaUserAgreementTerms',
    'metaUserAgreementPics',
  ]), [lastNameFirst])
  // transform the countries to usable format in select
  const countryCallingCodeOptions = useMemo(() => (
    countriesEntities.map((country) => ({
      label: country.callingCode,
      value: country.callingCode,
    }))
  ), [countriesEntities])

  const resolvedFormConfig = useMemo(() => {
    const requireEmailByRegisterApproach = _.every(supportedRegisterApproaches, {
      inputType: 'email',
    })
    const requireEmailTokenByRegisterApproach = _.some(supportedRegisterApproaches, {
      verificationType: 'emailToken',
    })
    const requirePhoneByRegisterApproach = _.every(supportedRegisterApproaches, {
      inputType: 'phone',
    })
    const requireSmsTokenByRegisterApproach = _.some(supportedRegisterApproaches, {
      verificationType: 'smsToken',
    })
    const showEmail = _.has(formConfig, 'email') || requireEmailByRegisterApproach
    const showPhone = _.has(formConfig, 'phone') || requirePhoneByRegisterApproach
    const modifiedFormConfig = {
      ...formConfig,
      ...(
        showEmail && {
          email: {
            ..._.get(formConfig, 'email', {}),
            required: _.get(formConfig, 'email.required', false) || requireEmailByRegisterApproach,
            requireToken:
              _.get(formConfig, 'email.requireToken', false)
              || requireEmailTokenByRegisterApproach
              || requireEmailToken,
          },
        }
      ),
      ...(
        showPhone && {
          phone: {
            ..._.get(formConfig, 'phone', {}),
            required: _.get(formConfig, 'phone.required', false) || requirePhoneByRegisterApproach,
            requireToken:
              _.get(formConfig, 'phone.requireToken', false)
              || requireSmsTokenByRegisterApproach
              || requireSmsToken,
          },
        }
      ),
    }
    // merge in meta definitions to registration fields
    _.forEach(metaOptions, (meta) => {
      let type = null
      switch (meta.fieldType) {
        case 'enum':
          type = 'select';
          break;
        case 'boolean':
          type = 'checkbox';
          break;
        default:
          // handle other cases if needed
          break;
      }
      modifiedFormConfig[`meta${_.upperFirst(_.camelCase(meta.fieldKey))}`] = {
        required: meta.validatePresence,
        type,
        options: meta.metaDefinitionValueListItems || undefined,
        validateFormat: meta.validateFormat,
        validateFormatErrorMessage: meta.validateFormatErrorMessage,
      }
    })
    return _.pick(modifiedFormConfig, availableFieldKeys)
  }, [availableFieldKeys, formConfig, metaOptions, supportedRegisterApproaches])

  const formInitialValues = useMemo(
    () => (
      getRegistrationFormInitialValues({
        formConfig: resolvedFormConfig,
        defaultCountryCallingCode,
        defaultLocale,
        defaultValues,
      })
    ),
    [defaultLocale, defaultCountryCallingCode, resolvedFormConfig, defaultValues],
  )

  const formValidationSchema = useMemo(() => (
    transformRegistrationFormValidationSchema(resolvedFormConfig)
  ), [resolvedFormConfig])

  const fields = useMemo(() => {
    const userRegistrationFields = resolvedFormConfig
    // define the form field sequence
    const keys = _.intersection(availableFieldKeys, _.keys(userRegistrationFields))

    const result = []
    _.forEach(keys, (key) => {
      const field = _.get(userRegistrationFields, key, {})

      let options = field.options || null
      let type = field.type || 'text'
      let label = t(`screens.registration.form.${key}`, { context: 'label' })
      let labelType = 'plainText'
      let placeholder = t(`screens.registration.form.${key}`, { context: 'placeholder' })
      let inputProps = {}
      let required = _.get(field, 'required', false)
      let autoComplete

      switch (key) {
        // case 'metaBranchCode':
        //   if (!_.isEmpty(branchOptions)) {
        //     options = _.compact(
        //       _.map(branchOptions, _value => {
        //         if (_.isEmpty(_value)) return null
        //         return {
        //           value: _value.code,
        //           name: _value.name,
        //         }
        //       }),
        //     )
        //     type = 'select'
        //   }
        //   break
        case 'metaUserAgreementTerms':
          labelType = 'i18nTransComponentKey'
          label = {
            i18nKey: `screens.registration.form.${key}`,
            tOptions: {
              context: 'label',
            },
            links: [
              `/pages/${termsPageUrlSlug}`,
            ],
          }
          break
        case 'metaUserAgreementPics':
          labelType = 'i18nTransComponentKey'
          label = {
            i18nKey: `screens.registration.form.${key}`,
            tOptions: {
              context: 'label',
            },
            links: [
              `/pages/${picsPageUrlSlug}`,
            ],
          }
          break

        case 'dateOfBirth':
          label = t('screens.registration.form.birthday')
          inputProps = {
            inputFormat: birthdayInputFormat,
            minimumDate: new Date(moment().subtract(100, 'years').valueOf()),
            maximumDate: new Date(moment().subtract(13, 'years').valueOf()),
            defaultDate: new Date(moment().subtract(14, 'years').valueOf()),
          }
          type = 'datepicker'
          break

        case 'salutation':
          label = t(`screens.registration.form.${key}.title`)
          placeholder = '--'
          options = salutationOptions
          type = 'select'
          break

        case 'gender':
          label = t(`screens.registration.form.${key}.title`)
          placeholder = '--'
          options = genderOptions
          type = 'select'
          break

        case 'locale':
          if (_.size(localeOptions) <= 1) return
          options = localeOptions
          type = 'select'
          break

        case 'phone':
          // let [countryCallingCode = null, phoneNumber = null] = _.get(
          //   newRegistration,
          //   'phone',
          //   '',
          // ).split(' ')
          // if (_.isEmpty(countryCallingCode)) {
          //   countryCallingCode = CountryService.getDefaultCountryCallingCode()
          // }
          // value = {
          //   countryCallingCode,
          //   phoneNumber,
          // }
          type = 'phone'
          options = countryCallingCodeOptions
          required = _.every(supportedRegisterApproaches, { inputType: 'phone' })
            || required
          break

        case 'email':
          autoComplete = 'email'
          type = 'email'
          required = _.every(supportedRegisterApproaches, { inputType: 'email' })
            || required
          break
        case 'password':
        case 'passwordConfirmation':
          autoComplete = 'new-password'
          type = 'password'
          break
        case 'lastName':
          autoComplete = 'family-name'
          break
        case 'firstName':
          autoComplete = 'given-name'
          break
        case 'affiliatedUserMembershipCode':
          autoComplete = 'one-time-code'
          break

        default:
          break;
      }

      // add field
      result.push(
        _.omitBy(
          {
            ...field,
            autoComplete,
            label,
            labelType,
            placeholder,
            name: key,
            options: options || undefined,
            type,
            // value,
            ...inputProps,
            required,
          },
          _.isUndefined,
        ),
      )

      // special handling for `require_confirmation` fields
      if (_.has(field, 'requireConfirmation')) {
        const confirmKey = `${key}Confirmation`
        result.push({
          ..._.omit(field, ['requireConfirmation']),
          label: t(`screens.registration.form.${confirmKey}`, { context: 'label' }),
          name: confirmKey,
          autoComplete: 'email',
          frontendOnly: /emailConfirmation/.test(confirmKey),
        })
      }

      // special handling if email field require email token
      if (key === 'email' && field.requireToken) {
        result.push({
          name: 'emailTokenButton',
          type: 'sendTokenButton',
          frontendOnly: true,
          onSuccess: onRequestEmailTokenSuccess,
          onError: onRequestEmailTokenError,
        })
        if (showEmailVerificationCodeInput) {
          result.push({
            label: t('screens.registration.form.emailToken', { context: 'label' }),
            name: 'emailToken',
            autoComplete: 'one-time-code',
            // required: true,
            type: 'pincode',
          })
        }
      }

      // special handling if key='phone'
      if (key === 'phone' && field.requireToken) {
        result.push({
          name: 'smsTokenButton',
          type: 'sendTokenButton',
          frontendOnly: true,
          onSuccess: onRequestSmsTokenSuccess,
          onError: onRequestSmsTokenError,
        })
        if (showVerificationCodeInput) {
          result.push({
            label: t('screens.registration.form.token', { context: 'label' }),
            name: 'token',
            autoComplete: 'one-time-code',
            // required: true,
            type: 'pincode',
          })
        }
      }
    })
    return result
  }, [
    availableFieldKeys,
    countryCallingCodeOptions,
    genderOptions,
    localeOptions,
    resolvedFormConfig,
    salutationOptions,
    showEmailVerificationCodeInput,
    showVerificationCodeInput,
    supportedRegisterApproaches,
  ])

  /**
   * fetchCountriesApi
   * function to call api and fetch countries for country calling code options
   */
  const fetchCountriesApi = useCallback(async () => {
    try {
      const fetchCountriesApiQuery = getSystemSetting('api.v2.addresses.countries.registration.ecom.query', {})
      const { countries } = await fetchCountries({
        params: {
          pageSize: 999,
          ...fetchCountriesApiQuery,
        },
        arrayFormat: 'brackets',
      })
      const countriesCodes = _.split(preferredCountryCodes, ' ')
      const firstCountryCode = _.head(countriesCodes)
      const firstCountry = _.find(countries, { alpha2: firstCountryCode }) || _.first(countries)
      setCountriesEntities(countries)
      setDefaultCountryCallingCode(_.get(firstCountry, 'callingCode'))
      setFormDisabled(false)
      setPageReady(true)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message, { state: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSystemSetting, fetchCountries])

  /**
   * handle EmailTokenButton callbacks
   */
  function onRequestEmailTokenSuccess() {
    setShowEmailVerificationCodeInput(true)
    // setShowVerificationCodeInput(false)
  }
  function onRequestEmailTokenError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message, { state: 'error' })
  }

  /**
   * handle SmsTokenButton callbacks
   */
  function onRequestSmsTokenSuccess() {
    setShowVerificationCodeInput(true)
    // setShowEmailVerificationCodeInput(false)
  }
  function onRequestSmsTokenError(error) {
    const generalError = _.get(error, 'generalError', {})
    alert.show(generalError.message, { state: 'error' })
  }

  /**
   * handleMergeCart
   * after user registration success, if a guest cart
   */
  async function handleMergeCart() {
    if (
      _.isEmpty(cartId)
      // skip create cart on dine in mode
      || _.isEqual(_.get(orderMethod, 'commerceType'), 'dineIn')
    ) return
    const options = {
      params: {
        includes: ['cart_line_properties', 'cart_shipment_ids'],
      },
    }
    try {
      const origCartShipment = _.get(cart, 'cartShipments.0', {})
      const { cart: mergedCart } = await mergeCart(options)
      if (!_.isEmpty(orderMethod)) {
        const deliveryType = _.get(orderMethod, 'deliveryType')
        const deliveryTypeForWarehouse = _.get(orderMethod, 'deliveryTypeForWarehouse')
        const selectedStoreId = _.get(store, 'id')
        const selectedDeliveryType = (selectedStoreId === 'warehouse' && deliveryTypeForWarehouse)
          || deliveryType
          || _.get(origCartShipment, 'deliveryType.code')
        const updateDeliveryTypeAction = _.omitBy({
          actionType: 'update_cart_shipment',
          id: _.get(mergedCart, 'cartShipmentIds.0'),
          deliveryType: selectedDeliveryType,
          deliveryAddressId: _.get(origCartShipment, 'deliveryAddress.id'),
          pickupStoreId: (selectedStoreId !== 'warehouse' ? selectedStoreId : _.get(origCartShipment, 'pickupStore.id')),
        }, _.isNil)
        await updateCart({
          cartId: _.get(mergedCart, 'id'),
          payload: {
            data: {
              actions: [
                updateDeliveryTypeAction,
              ],
            },
            batchUpdateMode: 2,
          },
          params: getParams({
            includeGroups: ['basic'],
          }),
        })
      }

      // deliveryType, deliveryAddressId, pickupStoreId
    } catch (error) {
      // continue the login process
      console.warn('[Project] handleMergeCart error: ', error)
    }
  }

  /**
   * handleRegistration
   *
   * @param {*} values, object contain all value from input
   */
  async function handleRegistration(values) {
    alert.remove()
    setFormDisabled(true)

    const userRegistrationFields = [
      ..._.keys(formConfig),
      'token',
      'emailToken',
    ]
    const approach = getRegistrationApproach(values)

    // pre-processing of registration fields in preparation for api submission
    // and convert keys starting with `meta` to nested meta object
    const newValues = { meta: {} }
    _.forEach(Object.keys(values), (key) => {
      if (/^meta/.test(key)) {
        newValues.meta[_.camelCase(key.replace(/^meta/, ''))] = values[key]
      } else {
        newValues[key] = values[key]
      }
    })

    // prepare api call payload
    const data = {
      registration: {
        approach,
        ..._.omitBy(_.pick(newValues, userRegistrationFields), _.isNil),
      },
    }

    // calling api for registration and control the flow of page redirect
    try {
      const { session } = await createRegistration(data)

      // update auth token and user id
      await setAuth({
        ...auth,
        ..._.pick(session, ['authToken', 'userId']),
      })

      await handleMergeCart()

      try {
        trackEvent('customerRegister', {}, {
          user: _.pick(values, ['email', 'phone', 'firstName', 'lastName']),
        })
      } catch (ex) {
        // do nothing
      }

      // handle redirect to different page after sign in successfully
      navigate(
        _.isEmpty(redirectUrl) ? '/account/' : redirectUrl,
        {
          replace: true,
          state: callbackState,
        },
      )
    } catch (error) {
      setFormDisabled(false)
      throw error
    }
  }

  /**
   * handleSubmit
   *
   * Formik onSubmit callback
   *
   * @param {*} values form values from Formik
   * @param {*} actions includes an object containing a subset of the injected props and methods
   */
  const handleSubmit = async (values, actions) => {
    alert.remove()
    try {
      await handleRegistration(values)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      const validationError = _.get(error, 'validationError', {})
      const errorMessage = _.isEmpty(validationError.message)
        ? generalError.message
        : validationError.message
      alert.show(errorMessage, { state: 'error' })
    } finally {
      actions.setSubmitting(false)
    }
  }

  /**
   * onSocialSignIn
   *
   * redirect facebook
   * TODO implement logical for other sacial platforms
   */

  async function onSocialSignIn(provider) {
    alert.remove()
    const url = new URI(location.href)
    // use a cross-platform application like NGROK to test in localhost
    const urlRedirect = `${url.protocol()}://${url.host()}/${pathContext.locale}/oauth/register`

    // prepare api call payload
    const data = {
      omniAuthOtp: {
        provider,
        mode: 'redirect',
        redirect: false,
        redirectUrl: urlRedirect,
      },
    }

    // calling api for create session and control the flow of page redirect
    try {
      const { omniAuthOtp } = await createUsersOmniAuthOtps(data)
      const urlAPI = _.get(api, 'host')
      const urlAuth = _.get(omniAuthOtp, 'requestPhasePath')

      const newUrl = `${urlAPI}${urlAuth}`
      // handle redirect to different page after sign in successfully
      navigate(
        newUrl,
        { replace: false },
      )
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message, { state: 'error' })

      setFormDisabled(false)

      throw error
    }
  }

  const onLoginClick = () => {
    // pass through state to login form
    navigate(
      '/login/',
      _.pick(location, ['state']),
    )
  }

  /**
   * redirect to account page if user is already logged in
   */
  useEffect(() => {
    if (auth.userId) {
      navigate('/account/', { replace: true })
    }
  }, [])

  /**
   * get countries for country call code option
   */
  useEffect(() => {
    fetchCountriesApi()

    return () => {
      cancelRequest.cancelAll(['fetchCountries'])
    }
  }, [fetchCountriesApi])

  /**
   * Show token input if defaultValues exist
   */
  useEffect(() => {
    const {
      token,
      emailToken,
    } = defaultValues || {}
    if (!_.isEmpty(token)) {
      setShowVerificationCodeInput(true)
    }
    if (!_.isEmpty(emailToken)) {
      setShowEmailVerificationCodeInput(true)
    }
  }, [defaultValues])

  /**
   * cancel api call when leaving the page
   */
  useEffect(() => () => (
    cancelRequest.cancelAll(['createRegistration'])
  ), [])

  const viewProps = {
    pageReady,
    seoTitle,
  }

  const formPorps = {
    fields,
    formDisabled,
    socialAvailable,
    onLoginClick,
    onSocialSignIn,
  }

  return (
    <RegistrationView {...viewProps}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validateOnChange
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        <RegistrationForm {...formPorps} />
      </Formik>
    </RegistrationView>
  )
}

export default RegistrationController
